import { safeStorage } from '@daily/shared/lib/safeStorage';
import { atom, useAtomValue, useSetAtom } from 'jotai';

const safeLocalStorage = safeStorage(() => localStorage);

/**
 * Returns a flag indicating whether the audio is being shared by an internal FLOWN user (facilitator or flown roles).
 * This is used to determine whether to hide the tile that shows the screen or not
 */
const isInternalAudioShare = atom(false);
export const useSetIsInternalAudioShare = () =>
  useSetAtom(isInternalAudioShare);

export const useIsInternalAudioShare = () => useAtomValue(isInternalAudioShare);

// Create an initial getter that reads from localStorage - default to true
const getInitialAutoPIPValue = () => {
  const stored = safeLocalStorage.getItem('isAutoPIPEnabled') || true;
  return stored === null ? true : JSON.parse(stored);
};

// Create the atom with an onMount to load the initial value
const isAutoPIPEnabledAtom = atom(
  getInitialAutoPIPValue(), // initial value from localStorage
  (_, set, value: boolean) => {
    set(isAutoPIPEnabledAtom, value);
    safeLocalStorage.setItem('isAutoPIPEnabled', JSON.stringify(value));
  }
);

export const useSetIsAutoPIPEnabled = () => useSetAtom(isAutoPIPEnabledAtom);
export const useIsAutoPIPEnabled = () =>
  useAtomValue<boolean>(isAutoPIPEnabledAtom);

const isPIPOnAtom = atom(false);
export const useSetIsPIP = () => useSetAtom(isPIPOnAtom);
export const useIsPIP = () => useAtomValue(isPIPOnAtom);
